import { environment } from 'src/environments/environment';

const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: any;

}
const Roles_list = { Admin: 'Admin', Inspector: 'Inspector', Workshop: 'Workshop', Ins: 'Ins'  }
const data: IMenuItem[] = [
  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `/dashboard`,
    roles: [Roles_list.Admin],
  },
  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `/inspection`,
    roles: [Roles_list.Inspector],
  },
  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `/workshop`,
    roles: [Roles_list.Workshop],
  },

  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `/approver`,
    roles: [Roles_list.Ins],
  },




  {
    icon: 'iconsminds-shop-4',
    label: 'menulist.inspectioninfo',
    to: `/inspectioninfo`,
    roles: [],
  },

  {
    icon: 'iconsminds-shop-4',
    label: 'menulist.workshopinfo',
    to: `/workshopinfo`,
    roles: [],
  },

  {
    icon: 'iconsminds-shop-4',
    label: 'menulist.approverinfo',
    to: `/approverinfo`,
    roles: [],
  },

  {
    icon: 'iconsminds-digital-drawing',
    label: 'menulist.Usermanagement',
    to: `/myprofile`,
    roles: [Roles_list.Admin],
    subs: [
      // {
      //   icon: 'simple-icon-paper-plane',
      //   label: 'menulist.Manage Roles',
      //   to: `/manageroles`,
      //   // roles: [UserRole.Admin],
      // },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menulist.Manage User',
        to: `/manageuser`,
        // roles: [UserRole.Admin],
      },
    ],
  },
];
export default data;
