import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { BodyComponent } from './module/body/body.component';
import { ErrorComponent } from './module/error/error.component';
import { UnauthorizedComponent } from './module/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    component: BodyComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [

      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
      // { path: 'myprofile', loadChildren: () => import('./main/app/myprofile/myprofile.module').then(m => m.MyprofileModule) },
      { path: 'manageroles', loadChildren: () => import('./pages/usermanagement/manageroles/manageroles.module').then(m => m.ManagerolesModule) },
      { path: 'manageuser', loadChildren: () => import('./pages/usermanagement/manageuser/manageuser.module').then(m => m.ManageuserModule) },
      { path: 'inspection', loadChildren: () => import('./pages/inspection/inspection/inspection.module').then(m => m.InspectionModule) },
      { path: 'inspectioninfo', loadChildren: () => import('./pages/inspection/inspectioninfo/inspectioninfo.module').then(m => m.InspectioninfoModule) },
      { path: 'workshop', loadChildren: () => import('./pages/workshop/workshop/workshop.module').then(m => m.WorkshopModule) },
      { path: 'workshopinfo', loadChildren: () => import('./pages/workshop/workshopinfo/workshopinfo.module').then(m => m.WorkshopinfoModule) },
      { path: 'approver', loadChildren: () => import('./pages/approver/approver/approver.module').then(m => m.ApproverModule) },
      { path: 'approverinfo', loadChildren: () => import('./pages/approver/approverinfo/approverinfo.module').then(m => m.ApproverinfoModule) },

    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },



  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
